import { Middleware } from '@nuxt/types';
import { useConfigStore } from '~/stores/config';

const middleware : Middleware = (context) => {
  const configStore = useConfigStore();
  const currentDate = new Date();
  const targetDate = new Date("2023-12-31T23:59:59");
  const sp_session = context.query.salesperson_session
  const salesCookie = context.app.$cookies.get("sale-person");

  if (salesCookie) {
    return
  } else if (currentDate < targetDate && !sp_session) {
    configStore.$patch((state) => {
      state.showLunchPopUp = true
      if (!context.from) {
        context.redirect('/' + state.storeConfig.store_code)
      }
    })
  }
};
export default middleware;
