// Microsoft Business Central auth

import { Middleware } from '@nuxt/types';

const getToken = async (context) => {
  const tenantId = context.$config.mbcTenantId;
  const clientId = context.$config.mbcClientId;
  const clientSecret = context.$config.mbcSecretId;
  const tokenEndpoint = `https://login.microsoftonline.com/${tenantId}/oauth2/v2.0/token`;

  const data = new URLSearchParams();
  data.append('grant_type', 'client_credentials');
  data.append('client_id', clientId);
  data.append('client_secret', clientSecret);
  data.append('scope', 'https://api.businesscentral.dynamics.com/.default');

  let token = null

  await context.app.$axios.post(tokenEndpoint, data)
    .then(response => {
      token = response.data.access_token;
    })
    .catch(error => {
      console.error('Error:', error);
    });

  return token
}

const middleware : Middleware = async (context) => {
  // const mbcToken = context.app.$cookies.get('mbcToken')
  // const customerToken = context.app.$cookies.get('vsf-customer')

  // if (!mbcToken && customerToken) {
  //   if(process.client) {
  //     context.app.$cookies.set('reloadPage', true)
  //   }
  //   const token = await getToken(context)
  //   context.app.$cookies.remove("mbcToken");
  //   context.app.$cookies.set('mbcToken', token, { maxAge: 60 * 45})
  // }
};
export default middleware;
