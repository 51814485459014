const fragmentCategory = `
  fragment CategoryFields on CategoryTree {
    is_anchor
    name
    position
    product_count
    uid
    url_path
    url_suffix
    include_in_menu
    level
    image
    description
    show_children_categories
    show_products
    selected_brands {
      image
      name
      option_id
      attribute_code
    }
  }
`;

export default `
  query categoryList {
    categories {
      items {
        ...CategoryFields
        children {
          ...CategoryFields
          children {
            ...CategoryFields
            children {
              ...CategoryFields
              children {
                ...CategoryFields
              }
            }
          }
        }
      }
    }
  }
  ${fragmentCategory}
`;
