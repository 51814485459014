export interface SortingModel {
  selected: string,
  options: SortingOption[]
}

export interface SortingOption {
  label: string,
  value: SortingOptionsValuesEnum
}

export enum SortingOptionsValuesEnum {
  DEFAULT = '',
  NAME_ASC = 'name_ASC',
  NAME_DESC = 'name_DESC',
  PRICE_ASC = 'price_ASC',
  PRICE_DESC = 'price_DESC',
  SKU_ASC = 'sku_ASC',
  SKU_DESC = 'sku_DESC'
}

export const sortingOptions: SortingOption[] = [
  {
    label: 'Sort by newest',
    value: SortingOptionsValuesEnum.DEFAULT,
  },
  {
    label: 'Sort: Name A-Z',
    value: SortingOptionsValuesEnum.NAME_ASC,
  },
  {
    label: 'Sort: Name Z-A',
    value: SortingOptionsValuesEnum.NAME_DESC,
  },
  {
    label: 'Sort: Price from low to high',
    value: SortingOptionsValuesEnum.PRICE_ASC,
  },
  {
    label: 'Sort: Price from high to low',
    value: SortingOptionsValuesEnum.PRICE_DESC,
  },
  {
    label: 'Sort: SKU A-Z',
    value: SortingOptionsValuesEnum.SKU_ASC,
  },
  {
    label: 'Sort: SKU Z-A',
    value: SortingOptionsValuesEnum.SKU_DESC,
  },
];
