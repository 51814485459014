import { Middleware } from '@nuxt/types';

const checkIpAddr = async (context) => {
  const data = await context.app.$axios.get('https://api.ipify.org?format=json')
  return data
}

const checkCountry = async (context, ip) => {
  const data = await context.app.$axios.get('http://ip-api.com/json/' + ip)
  context.app.$cookies.set("ip-country", data.data.countryCode, { maxAge: 31536000 })
  return data
}

const checkAvailStores = async (context) => {
  const { data } = await context.app.$vsf.$magento.api.availableStores({ availableStores: 'availableStores' } , {})
  return data
}

const changeStore = (context, store_code, availStores) => {
    const store = availStores.availableStores.find(s => s.code === store_code && s.store_code === store_code)
    context.app.$vsf.$magento.config.state.setStore(store.store_code);
    context. app.$vsf.$magento.config.state.setCurrency(store.default_display_currency_code);
    context.app.$vsf.$magento.config.state.setLocale(store.store_code);
    // const newStoreUrl = app.switchLocalePath(store.store_code);
    const newStoreUrl = '/' + store.store_code;
    context.redirect(newStoreUrl)
    if (process.client) {
      window.location.reload();
    }
}

const middleware : Middleware = async (context) => {
  const cCode = context.app.$cookies.get("ip-country")

  if (cCode !== undefined) return

  const data = await checkIpAddr(context)
  const cData = await checkCountry(context, data.data.ip)
  const availStores = await checkAvailStores(context)

  switch (cData.data.countryCode) {
    case 'NL':
      changeStore(context, 'nl', availStores)
      break
    case 'FR':
      changeStore(context, 'fr', availStores)
      break
    case 'DE':
        changeStore(context, 'de', availStores)
        break
    case 'ES':
      changeStore(context, 'es', availStores)
      break
    default:
      changeStore(context, 'en', availStores)
      break
  }
  
};
export default middleware;
